import React from "react";
import PropTypes from "prop-types";
const year = new Date()?.getFullYear();

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: "none" } : {}}>
    <p className="copyright"> &copy;{year}</p>
    <p className="copyright">
      Built by Derek Koloditch and Ian Campbell
      using <a href="https://www.gatsbyjs.org/">Gatsby.js</a> & Dimension.
    </p>
  </footer>
);

Footer.propTypes = {
  timeout: PropTypes.bool
};

export default Footer;
