import PropTypes from "prop-types";
import React from "react";
import group from "../images/group.jpg";
// import pic01 from "../images/pic01.jpg";
import crosstownCorners from "../images/crosstown_corners_cover.jpg";
import elevatorCover from "../images/elevator_cover.jpg";
import recurrentCover from "../images/recurrent_cover.png";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: { body: '' } };
    this.handleEmailMessage = this.handleEmailMessage.bind(this);
  };

  handleEmailMessage(event) {
    this.setState({ email: { body: event?.target?.value?.toString() } });
  };

  render() {
    let close = (
      <div
        className="close"
        role="none"
        onClick={() => this.props.onCloseArticle()}
      ></div>
    );

    const subject = 'I 🔊 aeoa';
    const body = this?.state?.email?.body;
    // translate any newlines for url params:
    const escapedBody = encodeURIComponent(body);
    const action = `mailto:contact@afterpostmodernism.com?subject=${subject}&body=${escapedBody}`;

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: "flex" } : { display: "none" }}>

        <article
          id="work"
          className={`${this.props.article === "work" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}>

          <h2 className="major">Discography</h2>

          <span className="image main">
            <a
              href="https://alleyesonathena.bandcamp.com/releases"
              target="_blank"
              rel="noreferrer">
              <img src={recurrentCover} alt="" />
            </a>
          </span>
          <p>
            Recurrent EP (2020)
          </p>

          <span className="image main">
            <a
              href="https://alleyesonathena.bandcamp.com/releases"
              target="_blank"
              rel="noreferrer">
              <img src={elevatorCover} alt="" />
            </a>
          </span>
          <p>
            Elevator EP (2014)
          </p>

          <span className="image main">
            <a
              href="http://www.mixcloud.com/AllEyesOnAthena/crosstown-corners/"
              target="_blank"
              rel="noreferrer">
              <img src={crosstownCorners} alt="" />
            </a>
          </span>
            Crosstown Corners Mix (2012)
          <p>
          </p>

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === "about" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}>
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={group} alt="" />
          </span>
          <p>
            Formed in early 2008, All Eyes On Athena is comprised of Ian
            Campbell and Derek Koloditch. Both long-time DJs and curious minds,
            the two met while studying philosophy in college and began their
            musical collaboration as a DJ tag-team in 2004. Their mixing
            experience and shared taste in electronic music eventually lead them
            to begin writing, producing, and releasing their own work. All Eyes
            On Athena fuses elements of house, electroclash, drum and bass, and
            techno. They are based in Denver, Colorado.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === "contact" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}>
          <h2 className="major">Contact</h2>
          <form
            method="post"
            encType="text/plain"
            action={action}
            target='_blank'
            rel='noopener noreferrer'>
            {/*
              // reinstate these fields as necessary. using the default browser
              // email doesn't necessitate a name or email since those are
              // provided by default.
              <div className="field half first">
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" />
              </div>
              <div className="field half">
                <label htmlFor="email">Email</label>
                <input type="text" name="email" id="email" />
              </div>
            */}
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                rows="5"
                onChange={this.handleEmailMessage}
                aria-label="Message"/>
            </div>
            <ul className="actions">
              <li>
                <input
                  type="submit"
                  value="Get In Touch"
                  disabled={!body}
                  className="special"
                  aria-label="Mute volume" />
              </li>
              {/*<li>
                <input type="reset" value="Reset" />
              </li>*/}
            </ul>
          </form>

          <ul className="icons">
            <li>
              <a href="http://www.facebook.com/alleyesonathena"
                className="icon fa-facebook"
                target='_blank'
                rel="noopener noreferrer">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="http://alleyesonathena.bandcamp.com/"
                className="icon fa-bandcamp"
                target='_blank'
                rel="noopener noreferrer">
                <span className="label">Bandcamp</span>
              </a>
            </li>
            <li>
              <a
                href="http://open.spotify.com/artist/6Kvr3JIRV3CuJWTqWxsJpF"
                className="icon fa-spotify"
                target='_blank'
                rel="noopener noreferrer">
                <span className="label">Spotify</span>
              </a>
            </li>
            <li>
              <a
                href="http://www.mixcloud.com/AllEyesOnAthena/crosstown-corners/"
                className="icon fa-mixcloud"
                target='_blank'
                rel="noopener noreferrer">
                <span className="label">Mixcloud</span>
              </a>
            </li>
            <li>
              <a
                href="http://www.soundcloud.com/alleyesonathena"
                className="icon fa-soundcloud"
                target='_blank'
                rel="noopener noreferrer">
                <span className="label">Soundcloud</span>
              </a>
            </li>
            <li>
              <a
                href="http://www.afterpostmodernism.com/"
                className="icon fa-headphones"
                target='_blank'
                rel="noopener noreferrer">
                <span className="label">Afterpostmodernism</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    );
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired
};

export default Main;
