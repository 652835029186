import PropTypes from "prop-types";
import React from "react";
import logo from "../images/aeoa_logo_white_on_transparent_150x150_v6.png";

const Header = props => (
  <header id="header" style={props.timeout ? { display: "none" } : {}}>
    <div className="">
      <img src={logo} alt="" className="icon" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Recurrent EP</h1>
        <p>Out Now</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("about");
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("work");
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("contact");
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool
};

export default Header;
